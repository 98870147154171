import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"

const parrafo = `A mediados de los años sesenta, por iniciativa del Padre Jean Sonet sj, un pequeño grupo de egresados de la primera promoción de la Facultad de Ciencias Económicas y de Administración de la UCC, viajó a Bélgica patrocinado por el gobierno de ese país, con el objetivo de realizar una estancia de capacitación en la Universidad Católica de Lovaina.

Sin embargo, el verdadero propósito del viaje trascendía lo estrictamente formativo. Convencido de que el gran problema de las organizaciones —ya fueran del sector privado, público o de la sociedad civil— consistía en el desconocimiento o en el uso inadecuado de las herramientas del management por parte de sus directivos, y de que las instituciones de educación superior debían asumir la responsabilidad de trabajar para corregirlo, el Padre Sonet encomendó a los becarios, investigar y conocer en detalle las características y la dinámica de los distintos centros especializados que en las universidades belgas trataban la materia, de manera que pudiera evaluarse la posibilidad de replicar dicho modelo en Córdoba.

Esta iniciativa dio como resultado la fundación en agosto de 1967, del Centro para el Perfeccionamiento en Administración de Empresas de la UCC (Cepade) —origen histórico del hoy ICDA—, primer centro de formación en management del interior del país.

La buena recepción que tuvo Cepade por parte de la sociedad cordobesa, fue un estímulo para que en 1972 y 1977, se pusieran en marcha dos proyectos paralelos y complementarios: el Centro de Investigación para la Administración Pública (Cipeap) y el Centro de Investigación para la Administración Cooperativa (Cipac).

Un año después de la creación de este último, en 1978, el entonces Provincial de la Compañía de Jesús en Argentina, Padre Jorge Mario Bergoglio sj, firmó la resolución que aprobó la integración de los tres centros bajo la denominación actual, Instituto de Ciencias de la Administración – ICDA.

Fiel a esta historia, desde hace medio siglo el ICDA ha sido pionero en la creación, la transmisión y la aplicación de herramientas de management, que ayuden a las personas y a las organizaciones a generar —de manera sustentable— riqueza, empleo y bienestar.`

const Institutional = () => (
  <Layout>
    <SEO title="Nuestra Historia - ICDA" />
    <section>
      <div>
        <div className="w-100 d-flex bg-light inst-img">
          <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
            <h2 className="mb-4 fw-bold text-uppercase title">Institucional</h2>
            <h3>Nuestra Historia</h3>
          </div>
        </div>
        <div>
          <div className="container my-5 main">
            <div className="mb-4">
              <ReactMarkdown source={parrafo} className="format-markdown" />
            </div>
            <Link to="https://www.ucc.edu.ar/" className="btn btn-primary">
              Ir a Universidad Católica Córdoba
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Institutional
